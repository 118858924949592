// src/Card.js
import React from 'react';
import './Card.css';

function Card({ title, description, type }) {
  return (
    <div className="card">
      <div className="card-header">
        <h3>{title}</h3>
        <span className="card-type">{type}</span>
      </div>
      <p>{description}</p>
    </div>
  );
}

export default Card;
