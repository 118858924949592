// src/Header.js
import React from 'react';
import './Header.css'; // Create this file for styling

function Header() {
  return (
    <div className="header">
      {/* <button className="contact-button">Get in touch</button>
      <div className="icons"> */}
        {/* Add your icons here */}
        {/* <img src="path/to/icon1.png" alt="Icon 1" />
        <img src="path/to/icon2.png" alt="Icon 2" />
        <img src="path/to/icon3.png" alt="Icon 3" />
        <img src="path/to/icon4.png" alt="Icon 4" />
      </div> */}
    </div>
  );
}

export default Header;